window.am = window.am || {};

window.am.sitecoreHelper = new function() {
    const that = this;

    that.isPageEditor = function() {
        return (
            window.Sitecore !== undefined &&
            window.Sitecore.PageModes !== undefined &&
            window.Sitecore.PageModes.PageEditor !== undefined);
    };

    that.init = function () {
        if (that.isPageEditor()) {
            import(/* webpackChunkName: "sitecoreExperienceEditor" */ 'Feature/Common/code/Styles/sitecoreExperienceEditor.scss');
        }
    };
};

window.am.sitecoreHelper.init();